import React, { Component } from "react";
import { FiSend } from "react-icons/fi";
import { Link } from 'react-router-dom';

import aboutImg1 from "../../../assets/images/about/about-101.jpg";

class About extends Component{
    render(){
        let title = 'Our Values',

        // description = "At Fathers House, our mission is to create a sanctuary where the Presence of God is experienced. We aim to foster a Kingdom Family atmosphere.",
        // description2 = "By experiencing the Father's Heart, we witness lives being changed, healed, and restored. The Word of God is our foundation, guiding us to deepen our understanding and live out Christ's teachings.",
        // description3 = "We are committed to discipleship through Life Groups and home ministry, helping members grow in faith and empower others. Honor is a core value; we uphold the dignity and worth of every person, reflecting God's love.",
        // description4 = "Our mission is to be a beacon of God's love, grace, and truth, inspiring and equipping our congregation to make a transformational impact in our community and beyond for God's glory."

        description = "At Fathers House, our mission is to create a sanctuary where the tangible Presence of God is experienced. Our goal is to create an atmosphere of Kingdom Family.",
        description2 = "Through experiencing the Father's Heart, we’ve seen countless lives changed, healed, and restored. The Word of God is the foundation to all we do at Fathers House, teaching to deepen our understanding and live out the teachings of Christ in our daily lives.",
        description3 = "We are dedicated to the journey of Discipleship through Life Groups and home ministry, empowering each member to grow in their faith and to equip others to do the same. In all our interactions, Honor remains a fundamental principle; we strive to uphold the dignity and worth of every person, embodying God's love in our relationships.",
        description4 = "Our mission is to be a beacon of God's love, grace, and truth, inspiring and equipping our congregation to make a transformational impact in our community and beyond, for the glory of God."

        // description = "At Fathers House, we strive to create a sanctuary where the Presence of God is felt and experienced. Our mission is to cultivate an atmosphere of Kingdom Family, where lives are changed, healed, and restored through experiencing the Father's Heart. We build our community on the foundation of the Word of God, teaching to deepen our understanding and live out Christ's teachings in daily life.",
        // description2 = "We are committed to discipleship through Life Groups and home ministry, helping each member grow in faith and empower others. Honor is a core value, as we uphold the dignity and worth of every person, reflecting God's love in all our interactions. Our goal is to be a beacon of God's love, grace, and truth, inspiring and equipping our congregation to make a transformational impact in our community and beyond for God's glory."
        
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-4 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src={aboutImg1} alt="About Image"/>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/* <div className="icon"> */}
                                            {/* <FiSend /> */}
                                        {/* </div> */}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <p className="description">{description3}</p>
                                        <p className="description">{description4}</p>
                                        {/* <p className="description">{description5}</p> */}
                                        {/* <div className="purchase-btn">
                                            <Link className="btn-transparent" to="/">Learn More</Link>
                                        </div> */}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;