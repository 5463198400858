import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

import Slider from "react-slick";
import { slickDot } from "../page-demo/script";

// import { Link as ScrollTo } from "react-scroll"
import { Link } from "react-router-dom";


import Header from "../component/header/Header";
// import Header from "../component/header/HeaderFive"; // uncomment to see other pages

import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import SliderTwo from "../component/slider/SliderTwo";
import SliderWithVideo from "../component/slider/SliderWIthVideo";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";




// import image1 from "../assets/images/bg/bg-image-3-overlay.jpg";
import portfolio1 from "../assets/images/portfolio/portfolio-101.png";
import portfolio2 from "../assets/images/portfolio/portfolio-102.png";
import portfolio3 from "../assets/images/portfolio/portfolio-103.png";
import portfolio4 from "../assets/images/portfolio/portfolio-104.png";
import portfolio5 from "../assets/images/portfolio/portfolio-105.png";
// import portfolio6 from "../assets/images/portfolio/portfolio-106.jpg";
// import portfolio7 from "../assets/images/portfolio/portfolio-107.jpg";
// import portfolio8 from "../assets/images/portfolio/portfolio-108.jpg";
// import portfolio9 from "../assets/images/portfolio/portfolio-109.jpg";

const Portfolio_image1 = <img src={portfolio1} alt="image" />;
const Portfolio_image2 = <img src={portfolio2} alt="image" />;
const Portfolio_image3 = <img src={portfolio3} alt="image" />;
const Portfolio_image4 = <img src={portfolio4} alt="image" />;
const Portfolio_image5 = <img src={portfolio5} alt="image" />;
// const Portfolio_image6 = <img src={portfolio6} alt="image" />;
// const Portfolio_image7 = <img src={portfolio7} alt="image" />;
// const Portfolio_image8 = <img src={portfolio8} alt="image" />;
// const Portfolio_image9 = <img src={portfolio9} alt="image" />;

// content for slider
// const SlideList = [
//   {
//     textPosition: "text-center",
//     category: "",
//     title: "Introduce Our <br /> Creative Agency.",
//     description:
//       "There are many variations of passages of Lorem Ipsum <br /> available but the majority have suffered alteration.",
//     buttonText: "Imroz Service",
//     buttonText2: "See Projects",
//     buttonLink: "/contact",
//     buttonLink2: "/portfolio",
//   },
// ];

const list = [
  {
    image: Portfolio_image1,
    category: "",
    title: "",
    description: "",
  },
  {
    image: Portfolio_image2,
    category: "",
    title: "",
    description: "",
  },
  {
    image: Portfolio_image3,
    category: "",
    title: "",
    description: "",
  },
  {
    image: Portfolio_image4,
    category: "",
    title: "",
    description: "",
  },
  {
    image: Portfolio_image5,
    category: "",
    title: "",
    description: "",
  },
  // {
  //   image: Portfolio_image6,
  //   category: "",
  //   title: "",
  //   description: "",
  // },
  // {
  //   image: Portfolio_image7,
  //   category: "",
  //   title: "",
  //   description: "",
  // },
  // {
  //   image: Portfolio_image8,
  //   category: "",
  //   title: "",
  //   description: "",
  // },
  // {
  //   image: Portfolio_image9,
  //   category: "",
  //   title: "",
  //   description: "",
  // },
];





class MainDemo extends Component {
  render() {
    const PostList = BlogContent.slice(0, 3);
    return (
      <div className="active-dark">
        <Helmet pageTitle="Spokane Valley, WA" /> 

        {/* Main Demo Dark */}
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          {/* <SliderOne /> */}
          <SliderTwo />
          {/* <SliderWithVideo /> */}
        </div>
        {/* End Slider Area   */}

        {/* Our Values - Start About Area */}
        <div id="about" className="about-area ptb--120 bg_color--1">
          <About />
        </div>
        {/* End About Area */}

        {/* Our Mission - Start Service Area  */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Portfolio Area */}
        {/* <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio />
          </div>
        </div> */}
        {/* End Portfolio Area */}


        {/* Community - Carousel Here - Start Portfolio Area , was color--5*/}
        <div className="portfolio-area pt--120 pb--140 bg_color--1"> 
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    {/* <span className="subtitle">Our project</span> */}
                    <h2 className="title">Community</h2>
                    <p className="description">
                      We believe in living life together.
                      <br /> Join us and become a part of our Kingdom Family.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15 slickdot--20">
                    <Slider {...slickDot}>
                      {list.map((value, index) => (
                        <div className="single_im_portfolio" key={index}>
                          <div className="im_portfolio">
                            <div className="thumbnail_inner">
                              <div className="thumbnail">
                                {/* <Link to="/portfolio-details"> */}
                                  {value.image}
                                {/* </Link> */}
                              </div>
                            </div>
                            {/* <div className="content">
                              <div className="inner">
                                <div className="portfolio_heading">
                                  <div className="category_list">
                                    <Link to="/portfolio-details">
                                      {value.category}
                                    </Link>
                                  </div>
                                  <h4 className="title">
                                    <Link to="/portfolio-details">
                                      {value.title}
                                    </Link>
                                  </h4>
                                </div>
                                <div className="portfolio_hover">
                                  <p>{value.description}</p>
                                </div>
                              </div>
                            </div> */}
                            {/* <Link
                              className="transparent_link"
                              to="/portfolio-details"
                            ></Link> */}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}


        {/* Partner With Us - Start CounterUp Area */}
        <div className="rn-counterup-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  {/* <span className="subtitle">Experts growts</span> */}
                  <h2 className="title">Partner With Us</h2>
                  <p className="description">
                    Contribute to our mission of changing lives through the <br/> power of His Word, Community, and Discipleship.
                  </p>

                  {/* added header button for give now */}
                  <div className="header-btn">
                    <a
                      className="btn-default btn-border btn-opacity"
                      target="_blank"
                      href="https://donorbox.org/fhspokane"
                    >
                      <span>Give Now</span>
                    </a>
                  </div>


                </div>
              </div>
            </div>
            {/* <CounterOne /> */}
          </div>
        </div>
        {/* End CounterUp Area */}




        {/* Life Groups - Start Testimonial Area */}
        <div id="groups" className="rn-testimonial-area bg_color--1 ptb--120 text-center">
          {/* <div className="container"> */}
            <div className="section-title text-center mb-4">
              <h2 className="title">Life Groups</h2>
                <p className="description">
                  Get connected through life groups.
                </p>
            </div>
            {/* <Testimonial /> */}
            {/* Use this when we have life group pictures */}

            <div className="header-btn">
              <a
                className="btn-default btn-border btn-opacity"
                target="_blank"
                href="https://fhspokane.churchcenter.com/groups/small-groups"
              >
                <span>Life Groups</span>
              </a>
            </div>
          {/* </div> */}
        </div>
        {/* End Testimonial Area */}



        {/* Building the Fathers House - Start Area */}
        <div id="build" className="rn-counterup-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  {/* <span className="subtitle">Experts growts</span> */}
                  <h2 className="title">Building the Fathers House</h2>
                  <p className="description">
                    Sew into our future as we build our new home in Otis Orchards.
                  </p>

                  {/* added header button for give now */}
                  <div className="header-btn">
                    <a
                      className="btn-default btn-border btn-opacity"
                      target="_blank"
                      href="https://donorbox.org/fhspokane"
                    >
                      <span>Give to Building Fund</span>
                    </a>
                  </div>


                </div>
              </div>
            </div>
            {/* <CounterOne /> */}

            {/* add video here */}

          </div>
        </div>
        {/* End Area */}




        {/* Location - Start Brand Area */}
        <div className="rn-brand-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center mb--30">
                  {/* <span className="subtitle">Top clients</span> */}
                  <h2 className="title">Location</h2>
                  {/* <h2 className="title">We worked with brands.</h2> */}
                  
                  {/* <span className="subtitle">what's stories on</span> */}
                  <a target="_blank" href="https://maps.app.goo.gl/M32LRnt3eMJyKkpPA">
                    <h4 className="description">
                      1222 S McDonald Rd
                    </h4>
                    <h4 className="description">
                      Spokane Valley, WA 99203
                    </h4>
                  </a>

                </div>
              </div>
            </div>
            
            {/* <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div> */}

          </div>
        </div>
        {/* End Brand Area */}


        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop smooth={true} duration={800} showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </div>
    );
  }
}
export default MainDemo;
