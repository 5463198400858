import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import testimonial1 from "../assets//images/client/testimonial-1.jpg";
import testimonial2 from "../assets//images/client/testimonial-2.jpg";
import testimonial3 from "../assets//images/client/testimonial-3.jpg";
import testimonial4 from "../assets//images/client/testimonial-4.jpg";
import testimonial5 from "../assets//images/client/testimonial-5.jpg";
import testimonial6 from "../assets//images/client/testimonial-6.jpg";
import testimonial7 from "../assets//images/client/testimonial-7.jpg";
import testimonial8 from "../assets//images/client/testimonial-8.jpg";
import testimonial9 from "../assets//images/client/testimonial-8.jpg";

class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Aroma Women's Ministry </span> 
                        </h6>
                          <p>Meets every other week on Wednesdays from 6:30-9:30pm</p>
                      </div>
                      <div className="">
                      {/* className = inner */}
                        <p>
                          We are a group of woman who each carry a testimony and saw a need in our community. Knowing the impact that unity, prayer, worship and fellowship has, led us to open our doors in January of 2020. We felt a burning desire and a strong call to gather all women to come together in the Lord’s presence, to grow in relationship, get activated and rise up in our purpose.
                        </p>
                      </div>
                      {/* https://fhspokane.churchcenter.com/groups/small-groups/aroma-women-s-ministry */}
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Avdeyevs Family Life Group </span>
                        </h6>
                        <p>Meets every other week on Fridays from 6:30-10pm</p>
                      </div>
                      <div className="">
                        <p>
                          Alex and Jenny Avdeyev are passionate about families, fellowship, worship and diving deep into the word of God. 
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Elijah's Discipleship Group</span>
                        </h6>
                        <p>Meets on Wednesdays</p>
                      </div>
                      <div className="">
                        <p>
                          Discipling...
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Lindsay's Ladies Bible Study</span>
                        </h6>
                        <p>Meets on Thursdays</p>
                      </div>
                      <div className="">
                        <p>
                          We are ...
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>MAD (Make A Disciple) Mens Group </span>
                        </h6>
                        <p>Meets every other Wednesday @ 7:00 pm</p>
                      </div>
                      <div className="">
                        <p>
                          Make A Disciple is a Life Group that aims to gather men for prayer, worship, and fellowship in order to make strong men who are Godly leaders in their home.  Men of all ages are encouraged to come. 
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Mens Bible Study in Deer Park</span>
                        </h6>
                        <p>Meets every other week on Tuesdays from 6:30-9:30pm</p>
                      </div>
                      <div className="">
                        <p>
                          The drive of this group is the hunger for His presence and the desire to get to know God’s nature and image. We study the word of God, and seek the Lord, as well as enjoy fellowship with one another 
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Noah McConahy's Mens Group</span>
                        </h6>
                        <p>Meets every other Friday @ 7:00 pm</p>
                      </div>
                      <div className="">
                        <p>
                          We ...
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Pendletons Married Couples Group</span>
                        </h6>
                        <p>Meets every other Monday @ 7:00 pm</p>
                      </div>
                      <div className="">
                        <p>
                          We ...
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="author-info mb-4">
                        <h6>
                          <span>Yuliya's Life Group (Healing Journey for Women)</span>
                        </h6>
                        <p>Meets every other week on Fridays from 11am-2pm</p>
                      </div>
                      <div className="">
                        <p>
                          If your heart is in need of healing, we would like to invite you to our healing journey group where you can find support, prayer, encouragement and a safe place to be real and open. Our desire it to find healing, identity and learn to walk boldly in the purpose God has for you. 
                        </p>
                        <p>
                          "And if one member suffers, all the parts share the suffering; if one member is honored, all rejoice with it. Now you (collectively) are Christs body, and individually you are members of it, each with his own special purpose and function." 1 Corinthians 12:26-27
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                </div>

                <div className="col-lg-6 mt_md--40 mt_sm--40">
                  <TabList className="testimonial-thumb-wrapper">
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial1} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial2} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial3} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial4} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial5} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial6} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial7} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial8} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial8} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
