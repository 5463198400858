import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
// import image4 from "../../assets/images/bg/bg-image-36.jpg"

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--40',
        // category: '',
        title: 'WELCOME HOME',
        description: "Advancing the Kingdom, Nurturing Community, and Cultivating the Father's House.",
        buttonText: "Here's How ->",
        // buttonLink: '/contact-us'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--36',
        // category: '',
        title: 'HIS PRESENCE',
        description: 'Our mission is to create a sanctuary where the tangible Presence of God is experienced.',
        // buttonText: 'Contact Us',
        // buttonLink: '/contact-us'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--37',
        // category: '',
        title: 'KINGDOM FAMILY',
        description: 'Our goal is to create an atmosphere of Kingdom Family.',
        // buttonText: 'Contact Us',
        // buttonLink: '/blog'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--38',
        // category: '',
        title: 'THE FATHERS HEART',
        description: "Through experiencing the Father's Heart, we’ve seen countless lives changed, healed, and restored.",
        // buttonText: 'Contact Us',
        // buttonLink: '/blog'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--39',
        // category: '',
        title: 'THE WORD',
        description: "The Word of God is the foundation to all we do at Fathers House.",
        // buttonText: 'Contact Us',
        // buttonLink: '/blog'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--40', //bg_image--13
        // category: '',
        title: 'DISCIPLESHIP',
        description: "We are dedicated to the journey of Discipleship through Life Groups and home ministry.",
        // buttonText: 'Contact Us',
        // buttonLink: '/blog'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--41', //bg_image--13
        // category: '',
        title: 'HONOR',
        description: "Upholding each person's dignity is fundamental, embodying God's love in all relationships.",
        // buttonText: 'Contact Us',
        // buttonLink: '/blog'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--42', //bg_image--13
        // category: '',
        title: 'GENEROSITY',
        description: "Drawing from God's love, grace, and truth, we aim to empower our community to create positive change, reflecting God's glory.",
        // buttonText: 'Contact Us',
        // buttonLink: '/blog'
    }
]
class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light " {...slideSlick}>
                {SlideList.map((value , index) => (
                    // slider-100-height <- this class fixed the vertically centered issue 

                    // was previously slide-style-1
                    <div className={`slider-100-height slide slide-style-4 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner ${value.textPosition}`}>
                                        {/* {value.category ? <span>{value.category}</span> : ''} */}
                                        {value.title ? <h1 className="title">{value.title}</h1> : ''} 
                                        {/* {value.title ? <h1 className="title">{value.title}</h1> : ''}  */}
                                        {/*  theme-gradient */}
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {/* {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
  }
}
export default SliderOne;