import React, { Component } from "react";
import { Link as ScrollTo } from "react-scroll"
import { Link } from "react-router-dom";

import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

import logoLight from "../../assets/images/logo/logo-light.png";
const logoUrl = <img src={logoLight} alt="Digital Agency" />;

const SocialShare = [
  // { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  // { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/fathershousespokane/" },
  { Social: <FaYoutube />, link: "https://www.youtube.com/@fhspokane" },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-style-01 bg_color--6">
          {/* Start Call to Action Area  */}
          <div className="im-call-to-action-area ptb--70 im-separator">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                  <div className="inner">
                    <h3 className="text-white mb--2">
                      Sunday Gatherings @ 3:00 pm
                    </h3>
                    <h3 className="text-white mb--0">
                      Thursday Prayer @ 7:00 pm
                    </h3>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                  <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                    <a
                      className="btn-default btn-large btn-border btn-opacity"
                      target="_blank"
                      href="https://donorbox.org/fhspokane"
                    >
                      Give Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Call to Action Area  */}

          {/* Start Footer Area  */}
          <div className="footer-wrapper ptb--70">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="ft-text">
                    <div className="logo">
                      <Link to="/">{logoUrl}</Link>
                    </div>
                    <p>
                      Copyright © 2024 <Link to="/">Fathers House</Link> <br/> All rights reserved.
                    </p>
                  </div>
                </div>

                {/* Start Single Widget  */}
                <div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40">
                  <div className="footer-link">
                    {/* <h4>Fathers House</h4> */}
                    <ul className="ft-link">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      {/* <li>
                        <Link to="/about">Services</Link>
                      </li> */}
                      <li>
                        <ScrollTo to="about" smooth={true} duration={800} className="cursor-pointer">About</ScrollTo>
                      </li>
                      <li>
                        <ScrollTo to="groups" smooth={true} duration={800} className="cursor-pointer">Groups</ScrollTo>
                      </li>
                      {/* <li>
                        <Link to="/">Sermons</Link>
                      </li> */}

                      {/* <li>
                        <Link to="/contact">Connect</Link>
                      </li> */}

                    </ul>
                  </div>
                </div>
                {/* End Single Widget  */}

                {/* Start Single Widget  */}
                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                  <div className="footer-link">
                    {/* <h4>Connect</h4> */}
                    <ul className="ft-link">
                      {/* <li>
                        <Link to="/contact">Connect</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/about">Missions</Link>
                      </li> */}
                      <li>
                        <ScrollTo to="build" smooth={true} duration={800} className="cursor-pointer">Build His House</ScrollTo>
                      </li>
                      <li>
                        <a target="_blank" href="https://fhspokane.churchcenter.com/registrations/events/1680191">Serve</a>
                      </li>
                      {/* <li>
                        <Link to="/">Events</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* End Single Widget  */}

                {/* Start Single Widget  */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                  <div className="footer-link">
                    {/* <h4>Contact</h4> */}
                    <ul className="ft-link">
                      <li>
                        <a target="_blank" href="https://maps.app.goo.gl/M32LRnt3eMJyKkpPA">1222 S McDonald Rd <br/> Spokane Valley, WA 99216</a>
                      </li>
                      <li>
                        {" "}
                        <a href="mailto:info@fhspokane.com">info@fhspokane.com</a>
                      </li>
                      {/* <li>
                        Email:{" "}
                        <a href="mailto:hr@example.com">hr@example.com</a>
                      </li> */}
                      <li>
                        <a href="tel:509-352-5220">509-352-5220</a>
                      </li>
                    </ul>

                    <div className="social-share-inner mt--20">
                      <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                        {SocialShare.map((val, i) => (
                          <li key={i}>
                            <a href={`${val.link}`}>{val.Social}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* End Single Widget  */}
              </div>
            </div>
          </div>
          {/* End Footer Area  */}
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
