import blogImg1 from "../../assets/images/blog/blog-01.jpg";
import blogImg2 from "../../assets/images/blog/blog-02.jpg";
import blogImg3 from "../../assets/images/blog/blog-03.jpg";
// import blogImg4 from "../../assets/images/blog/blog-04.jpg";
// import blogImg5 from "../../assets/images/blog/blog-05.jpg";
// import blogImg6 from "../../assets/images/blog/blog-06.jpg";
// import blogImg7 from "../../assets/images/blog/blog-07.jpg";





const BlogContent = [
  {
    images: blogImg1,
    title: "What is the Difference between Web and Brand.",
    category: "App Development",
  },
  {
    images: blogImg2,
    title: "A big ticket gone to be an interesting look New York.",
    category: "React App",
  },
  {
    images: blogImg3,
    title: "Getting tickets to the big show have a closer look.",
    category: "Photoshop",
  },
  // {
  //   images: blogImg4,
  //   title: " Getting tickets to the big show",
  //   category: "Development",
  // },
  // {
  //   images: blogImg5,
  //   title: "A big ticket gone to be an interesting ",
  //   category: "Management",
  // },
  // {
  //   images: blogImg6,
  //   title: "The Home of the Future Could Bebes",
  //   category: "Design",
  // },
  // {
  //   images: blogImg7,
  //   title: "The Home of the Future Could Bebes",
  //   category: "Design",
  // },
];

export default BlogContent;
